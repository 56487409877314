import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { dataGetir, getCatagories, getVideos } from "./VideoSlice";

const initialState = {
  tokens: '',
}

export const getDataAndCategories = createAsyncThunk(
  'getDataAndCategories',
  async (_, { dispatch }) => {
    try {
      const data = await dispatch(dataGetir());
      await Promise.all([
        dispatch(getCatagories(data.payload)),
        dispatch(getVideos(data.payload)),
      ]);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const counterSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {

  }, extraReducers: (builder) => {

    builder.addCase(getDataAndCategories.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.length = 0
    })
    //* yuklemenın tamamlandıgı an
    builder.addCase(getDataAndCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.length = 1
    })
    //* hata aldıgımız an olarak datayı yorumlayabılıyoruz
    builder.addCase(getDataAndCategories.rejected, (state, action) => {
      state.loading = false
      state.error = "Error get data"
    })
  }
})


export const { scroolFunc } = counterSlice.actions
export default counterSlice.reducer