import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import Config from "../../../core/config"
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    categoryArr: [],
    loading: false,
    error: "",
    length: 0,
    allVideosArr: [],
    tokenArray: []
}
const uniqueId = uuidv4();
export const dataGetir = createAsyncThunk('dataGetir', async () => {

    const response = await axios({
        method: "post",
        url: `${Config.SITE_URL}/api/create_token`,
        data: JSON.stringify({
            udid: uniqueId,
            channelId: "1"
        }),
        headers: {
            "Content-Type": "application/json",
        },
        json: true
    })
    return response.data.data;
})
export const getCatagories = createAsyncThunk('getCatagories', async (tokens) => {
    const headers = {
        'Access-Token': tokens,
    }
    const response = await axios.get(`${Config.SITE_URL}/api/categories/1`, { headers })
    return response.data.data
});

export const getVideos = createAsyncThunk('getVideos', async (tokens) => {
    const headers = {
        'Access-Token': tokens
    }
    const response = await axios.get(`${Config.SITE_URL}/api/videos/1`, { headers })
    return response.data.data;
});

export const VideoSlice = createSlice({
    name: 'catagories',
    initialState,
    reducers: {
    }, extraReducers: (builder) => {
        builder.addCase(dataGetir.pending, (state, action) => {
            state.loading = true;
            state.error = "";
            state.length = 0
        })
        //* yuklemenın tamamlandıgı an
        builder.addCase(dataGetir.fulfilled, (state, action) => {
            state.tokenArray = action.payload;
            state.loading = false;
            state.length = 1
        })
        //* hata aldıgımız an olarak datayı yorumlayabılıyoruz
        builder.addCase(dataGetir.rejected, (state, action) => {
            state.loading = false
            state.error = "error fetching todos data"
        })
        builder.addCase(getCatagories.pending, (state, action) => {
            state.loading = true;
            state.error = "";
            state.length = 0
        })
        //* yuklemenın tamamlandıgı an
        builder.addCase(getCatagories.fulfilled, (state, action) => {
            state.categoryArr = action.payload;
            state.loading = false;
            state.length = 1
        })
        //* hata aldıgımız an olarak datayı yorumlayabılıyoruz
        builder.addCase(getCatagories.rejected, (state, action) => {
            state.loading = false
            state.error = "error fetching todos data"
        })

        builder.addCase(getVideos.pending, (state, action) => {
            state.loading = true;
            state.error = "";
            state.length = 0
        })
        //* yuklemenın tamamlandıgı an
        builder.addCase(getVideos.fulfilled, (state, action) => {
            state.allVideosArr = action.payload;
            state.loading = false;
            state.length = 1
        })
        //* hata aldıgımız an olarak datayı yorumlayabılıyoruz
        builder.addCase(getVideos.rejected, (state, action) => {
            state.loading = false
            state.error = "title data kısmı gelmiyor"
        })
    }
})

export const { scroolFunc } = VideoSlice.actions

export default VideoSlice.reducer