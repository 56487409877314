import React, { useEffect, useState } from "react"
import './css/AllVideosPage.css'
const AllVideosPage = ({ oItem, inpValue, on, setOn, controlArr }) => {
    const [icon, setIcon] = useState(<i className="fa-sharp fa-solid fa-chevron-down ms-1 "></i>)
    const [isLoading, setIsLoading] = useState(true)
    const [value, setValue] = useState(false)

    useEffect(() => {
        value ? setIcon(<i className="fa-sharp fa-solid fa-chevron-down ms-1"></i>) : setIcon(<i className="fa-sharp fa-solid fa-chevron-up ms-1"></i>)
    }, [value])
    useEffect(() => {
        !on && setValue(true)
    }, [controlArr.length])
    useEffect(() => {
        setIsLoading(true)

    }, [oItem.id, oItem.category_id])
    const handleOnload = () => {
        setIsLoading(false)
    }
    return (
        <div style={{ display: oItem.title.toLowerCase().includes(inpValue) ? 'block' : 'none' }} className={oItem.title.toLowerCase().includes(inpValue) ? "col-sm-12 pr-3 pl-3 pb-5 video-item" : "col-sm-12 pr-3 pl-3 pb-5"}>
            <div
                id="videos-text-container" className="pb-3"
            >
                <a className="blurb-title text-start " href={`https://www.youtube.com/embed/${oItem.youtube_video_id}`} target="_blank">
                    <h3 className="title-link" style={{ fontSize: "18px" }}>
                        {oItem.title}
                    </h3>
                </a>
                {
                    oItem.description && oItem.description.length > 0 &&
                    <div className="blurb-text text-start pb-3">
                        <div style={!value ? { opacity: 0 } : { opacity: 1 }}>
                            <div className="truncate" style={!value ? { fontSize: "1px" } : { fontSize: "15px" }} >
                                {oItem.description}
                            </div>
                        </div>

                        <div className="all-description" style={value ? { display: "none" } : { display: "block" }}>
                            {oItem.description}
                        </div>
                        <span className="read-more" onClick={() => { setValue(!value) }}>{!value ? "  Gizle  " : " Devamını oku "} {icon} </span>
                    </div>
                }
            </div>
            <div className="loading-panel" >
                <div className="loading-piece" style={isLoading ? { display: "block" } : { display: "none" }}>
                    <div className="loading-icon" style={{ backgroundImage: `url(${oItem.thumbnail})` }}>
                        <div className="datas-loading"></div>
                    </div>
                </div>
                <iframe
                    style={{ height: "25rem" }}
                    frameBorder="0"
                    scrolling="no"
                    id="videos-container"
                    loading="lazy"
                    src={`https://www.youtube.com/embed/${oItem.youtube_video_id}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    onLoad={handleOnload}
                ></iframe>
            </div>
        </div>
    )
}
export default AllVideosPage;