import React from 'react';
import ApplicationsPiece from './ApplicationsPiece';
import ApplicationsData from './ApplicationsData';
import './css/ApplicationsMade.css'




const ApplicationsMade = () => {
    return (
        <>
            <div className="container">
                <h2 className='applications-h2'>
                    Yapılan Uygulamalar
                </h2>
                <br />
                <div className="row row-30">
                    <div className="offset-xxl-1 col-xxl-10 offset-xxl-1 ">

                        <div className="row">
                            {
                                ApplicationsData.map((oItem,oIndex)=>{
                                    return(
                                        <div key={oIndex} className="col-12 col-sm-6 col-md-4 p-2">
                                            <ApplicationsPiece oItem={oItem} oIndex={oIndex}/>  
                                        </div>
                                        
                                    )
                                })
                            }
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}

export default ApplicationsMade;