class Config {
    constructor() {
        this.default()
    }

    default() {
        this.SITE_URL = 'https://drosmangonulal.com'
    }
}

export default new Config()
