import React, { useEffect, useState } from 'react';

const SidebarPiece = ({ setDisabledIndex ,oIndex, oItem, handleClick, setActiveId, buyukHarfYapVeSiralaCumleler, result, isDisabled, disabledIndex }) => {
    const [isActive, setIsActive] = useState(false)
    const [click, setClick] = useState(false)
    useEffect(() => {
        oIndex === 0 ? setIsActive(true) : setIsActive(false);
        // oIndex === 0 ? console.log(true) : console.log(false);
    }, [oIndex])
    useEffect(() => {
        if (oItem?.id == disabledIndex) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [disabledIndex])


    return (
        <>
            <li
                onClick={() => { handleClick(oIndex, oItem); setDisabledIndex(oItem.id) }}
                // key={oIndex}
                id={oItem.id}
                className={`${isActive && "sidebarActive"} ${oIndex}Li select-li h5 fw-normal ${isDisabled ? "disabled" : ""}`}
            >
                <a
                    onClick={() => { setActiveId(oIndex); setClick(!click) }}
                    style={{ fontSize: "16px" }}
                    className="rounded p-3 sidebar-item d-flex align-items-center justify-content-between"
                    href="#"
                >
                    <div className="sidebar-title pe-4 text-truncate">
                        <i className="align-item-center ps-1 pe-2 fa-solid fa-circle circleIcon"></i>
                        <span>{buyukHarfYapVeSiralaCumleler(oItem.name)}</span>
                    </div>
                    <div style={{ color: "lightgray" }} className="sidebar-length">
                        {result[oIndex]?.count}
                    </div>
                </a>
            </li>
        </>
    );
}

export default SidebarPiece;