import ImagePaths from "./assets/images/ImagePaths"

export default [
    {
        img:`${ImagePaths.uygulama_1}`,
        title:"Alerji Testleri",
        write:"Kliniğimizde laboratuvar testleri yaparak alerjik reaksiyonların teşhisi konulmakta ve uygun tedavi yöntemi sunulmaktadır."
    },
    {
        img:`${ImagePaths.uygulama_6}`,
        title:"Kişisel Tanı ve Tedavi",
        write:"Uygulanan testler ve teşhisler neticesinde hastalıkların tanısı tespit edilmekte ve tedavi süreci başlatılmaktadır."
    },
    {
        img:`${ImagePaths.uygulama_5}`,
        title:"Kan Tahlili",
        write:"Kliniğimizde kan grubu tespiti ve kanda bulunan bileşenlerin değerlerindeki durum değerlendirilmektedir."
    },
    {
        img:`${ImagePaths.uygulama_2}`,
        title:"Diyetisyen",
        write:"Vücut için gereken besin ihtiyacının ve vitamin desteğinin sağlanması için beslenme planı uygulanmaktadır."
    },
    {
        img:`${ImagePaths.uygulama_3}`,
        title:"Emzirme Danışmanlığı",
        write:"Emzirme döneminde desteğe ihtiyaç duyan ve neler yapabileceğini merak eden anneler için danışmanlık hizmetimiz bulunmaktadır."
    },
    {
        img:`${ImagePaths.uygulama_4}`,
        title:"Fizyoterapi",
        write:"Kliniğimizde çocukların kas ve kemik gelişimi, omurga sağlığı gibi önemli konularında fizyoterapi tedavi yöntemleri uygulanmaktadır. "
    },
    {
        img:`${ImagePaths.uygulama_8}`,
        title:"Psikolojik Destek",
        write:"Ruh ve beden sağlığınız için her zaman yanınızda olmaya ve psikolojik desteğimizi sunmaya hazırız."
    },
    {
        img:`${ImagePaths.uygulama_9}`,
        title:"Sağlıklı Çocuk İzleri",
        write:"Uygulanan testler ve teşhisler neticesinde çocuğunuzun genel sağlık durumu hakkında bilgi edinin."
    },
    {
        img:`${ImagePaths.uygulama_7}`,
        title:"Online Hasta Takibi",
        write:"Kliniğimizde uygulanan işlemlerin ardından aralıklarla hastalarımızın durumlarına yönelik kontroller gerçekleştirilmektedir."
    },
]