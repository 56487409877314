export default {
    telephoneIcon: require('./telephone-icon.svg').default,
    whatsUpIcon:require('./whatsapp-02.png'),
    facebookIcon: require('./facebook.svg').default,
    twitterIcon: require('./twitter.svg').default,
    instagramIcon: require('./instagram.svg').default,
    youtubeIcon: require('./youtube.svg').default,
    bpyYoutube: require('./youtube.png'),
    bpyInstagram: require('./instagram.png'),
    bpyOnline: require('./online.png'),
    person_1: require('./person-01-64x64.jpg'),
    person_2: require('./person-02-64x64.jpg'),
    person_3: require('./person-03-64x64.jpg'),
    person_4: require('./person-04-64x64.jpg'),
    person_5: require('./person-05-64x64.jpg'),
    linkImg_0: require('./linkImg-0.jpg'),
    linkImg_1: require('./linkImg-1.jpg'),
    linkImg_2: require('./linkImg-2.jpg'),
    linkImg_3: require('./linkImg-3.jpg'),
    linkImg_4: require('./linkImg-4.jpg'),
    bilgiPaylasim_0: require('./bilgiPaylasim-0.jpg'),
    bilgiPaylasim_1: require('./bilgiPaylasim-1.jpg'),
    bilgiPaylasim_2: require('./bilgiPaylasim-2.jpg'),
    uygulama_1:require('./Alerji-Testleri.png'),
    uygulama_2:require('./Diyetisyen.png'),
    uygulama_3:require('./Emzirme.png'),
    uygulama_4:require('./Fizyoterapi.png'),
    uygulama_5:require('./Kan-Tahlili.png'),
    uygulama_6:require('./Kişisel-Tanı-Ve-Tedavi.png'),
    uygulama_7:require('./Online-Takip.png'),
    uygulama_8:require('./Psikolojik-Destek.png'),
    uygulama_9:require('./Sağlıklı-Çocuk-İzleri.png')
}