import { useState } from "react";
import ImagePaths from "./assets/images/ImagePaths";
import "./css/Contacts.css";

const Contacts = () => {
    const[form,setForm]=useState()
    var name=document.getElementsByClassName('form-control');
    const onSubmitInput = () => {
        setForm("Ad/Soyad :"+name[0]?.value + "%0A" +"Telefon Numarası :"+ name[1]?.value + "%0A" +"Mesaj :" +name[2]?.value)
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-10 col-xl-12 col-md-12 col-sm-12 m-auto ">
                        <div className="container">
                            <div className="row row-30 justify-content-center">
                                {/* ********************Ekranın Sol Tarafı ********************************/}
                                <div data-wow-delay="0.2s"
                                    className="test blue wow bounceInLeft col-sm-11 col-md-6">
                                    <h2 className="contacts-title">
                                        Bizi Arayarak Randevunuzu Oluşturabilirsiniz
                                    </h2>
                                    <p className="blurb-text" style={{ padding: " 0 0 15px 0" }}>
                                        İster dahili telefon numaramızdan bize ulaşarak isterseniz de randevu formunu eksiksiz bir biçimde doldurarak uygun randevu günleri hakkında bilgi alabilirsiniz.
                                    </p>
                                    <div className="divider"></div>
                                    <h6 className="contact-adress">
                                        Kardeşler Mahallesi 51/4 Sokak Kapı No:3C Merkez/Sivas
                                    </h6>

                                    <div className="telephone-div">
                                    <div className="tel-contact-wrapper">
                                        <div className="telephone-item tel-link">
                                            <a href="https://wa.me/+905323990166" target="_blank">
                                            <img
                                                src={ImagePaths.whatsUpIcon}
                                                width={28}
                                                height={28}
                                            />
                                            </a>
                                           
                                        </div>
                                        
                                        <div className="telephone-body concat-tel-body">
                                            <a className="contact-link-number" href="https://wa.me/+905323990166" target="_blank">0532 399 01 66</a>
                                            <div className="telephone-text">
                                            WhatsApp Mesajlaşma ve Arama
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <ul className="list blurb-text">
                                        <li style={{ fontWeight: "bold" }} className="list-item">
                                            Çalışma Günleri
                                        </li>
                                        <li className="list-item">
                                            Pazartesi - Cumartesi: 08:30 - 18:00
                                        </li>
                                    </ul>
                                    <a className="link " href="mailto:md.gonulal@gmail.com">
                                        md.gonulal@gmail.com
                                    </a>
                                </div>
                                {/**********************Ekranın Sağ Tarafı*********************************/}
                                <div data-wow-delay="0.2s"
                                    className="test blue wow bounceInRight col-sm-11 col-md-6">
                                    <div className="form-box">
                                        <h2 className="contacts-title">İletişim Formu </h2>
                                        <form
                                            className="rd-form rd-mailform"
                                            data-form-output="contact-form"
                                            data-form-type="contact"
                                            method="post"
                                            action="components/rd-mailform/rd-mailform.php"
                                            noValidate="novalidate"
                                        >
                                            <div className="form-wrap">
                                                <label style={{ paddingTop: "0" }} className="form-label" htmlFor="input-name">
                                                    Adınız/Soyadınız *
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                    onChange={onSubmitInput}
                                                        className="form-control form-control-has-validation form-control-last-child"
                                                        id="input-name"
                                                        type="text"
                                                        name="name"
                                                        placeholder="Please enter your name"
                                                        data-constraints="@Required"
                                                    />
                                                    <span className="form-validation"></span>
                                                </div>
                                            </div>
                                            <div className="form-wrap">
                                                <label className="form-label" htmlFor="input-phone">
                                                    İletişim Numaranız *
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                    onChange={onSubmitInput}
                                                        className="form-control form-control-has-validation form-control-last-child"
                                                        id="input-phone"
                                                        type="text"
                                                        name="phone"
                                                        placeholder="Please enter your phone number"
                                                        data-constraints="@Numeric @Required"
                                                    />
                                                    <span className="form-validation"></span>
                                                </div>
                                            </div>
                                            <div className="form-wrap">
                                                <label className="form-label" htmlFor="input-message">
                                                    Mesajınız *
                                                </label>
                                                <div className="form-group">
                                                    <textarea
                                                    onChange={onSubmitInput}
                                                        className="form-control form-control-has-validation form-control-last-child"
                                                        id="input-message"
                                                        name="textarea"
                                                        placeholder="Please enter your message"
                                                        data-constraints="@Required"
                                                    ></textarea>
                                                    <span className="form-validation"></span>
                                                </div>
                                            </div>
                                            <div className="custom-control custom-checkbox"></div>
                                            <div className="rd-form-btn text-start">
                                                <a href={`https://wa.me/+905323990166?text=${form}`} target="_blank">
                                                <button className="btn btn-lg btn-block" type="button">
                                                    Gönder
                                                </button>
                                                </a>
                                               
                                            </div>
                                        </form>
                                        <div
                                            className="form-output snackbar snackbar-primary"
                                            id="contact-form"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contacts;
