import { useEffect } from "react";
import home_image from "./assets/homeImg.png"
import ImagePaths from "./assets/images/ImagePaths";
import WOW from "wowjs"
import "./css/Home.css";

const Home = () => {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();

  }, [])

  return (
    <>
      <div id="home-container" className="container">
        <div
          className="row row-30 justify-content-center justify-content-md-between flex-md-row-reverse justify-content-xxl-center
"
        >
          {/* Image-Container */}
          <div
            id="img-container"
            data-wow-delay="0.2s"
            className="test blue wow bounceInRight float col-auto col-sm-11 col-md-6 col-lg-5 col-xxl-4 float-none img-fluid"
          >
            <img
              id="home-img"
              src={home_image}
            />
          </div>
          {/* Write-Container */}
          <div
            id="write-container"
            data-wow-delay="0.2s"
            className="test blue wow bounceInLeft float col-auto col-sm-11 col-md-6 col-lg-7 col-xl-6 col-xxl-6"
          >
            <h1 className="home-title display-1">Uzm. Dr. Osman GÖNÜLAL</h1>
            <h3 style={{ color: "#177ee5" }} className="text-primary home-link">
            Çocuk Sağlığı ve Hastalıkları Uzmanı
            </h3>
            
            <div className="telephone-div">
            <div className="tel-item-wrapper">
            <div className="telephone-item">
                <a  href="https://wa.me/+905323990166" target="_blank">
                <img src={ImagePaths.whatsUpIcon} width={28} height={28} />
                </a>
              </div>
            </div>
              
              <div className="telephone-body">
                <a href="https://wa.me/+905323990166" target="_blank">0532 399 01 66</a>
                <div className="telephone-text">
                WhatsApp Mesajlaşma ve Arama
                </div>
              </div>
            </div>
            <h5 className="telephone-adress lh-base">
              Kardeşler Mahallesi 51/4
              <br />
              Sokak Kapı No:3C Merkez/Sivas
            </h5>
          </div>
        </div>
      </div>

    </>
  );
};
export default Home;
