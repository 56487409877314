import React, { useEffect, useState } from "react";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./Navbar";
import "./css/AllVideos.css"
import { useDispatch, useSelector } from "react-redux";
import { getDataAndCategories } from "../redux/features/slices/tokenSlice";
import AllVideosPage from "./AllVideosPage";
import SidebarPiece from "./services/SidebarPiece";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const AllVideos = ({ scroolActive, setScroolActive }) => {
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState();
  const [activeId, setActiveId] = useState(-1);
  const [inpActive, setInpActive] = useState(true);
  const [dataName, setDataName] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [inpValue, setInpValue] = useState("");
  const [videoLength, setVideoLength] = useState();
  const [controlArr, setControlArr] = useState([]);
  const [on, setOn] = useState(false);
  const dispatch = useDispatch();
  const videoKeys = useSelector((state) => state.catagories);
  const [disabledIndex, setDisabledIndex] = useState(null);
  const [width, setWidth] = useState(window.innerWidth)
  const [height,setHeight] =useState(window.scrollY)
  const [isShow,setIsShow]=useState(false)

  function scrollLong() {
    setHeight(window.scrollY)
  }
  useEffect(()=>{
    window.addEventListener('scroll', scrollLong);
  },[])

  useEffect(()=>{
      if (height<1000) {
        setIsShow(false)
      }else(
        setIsShow(true)
      )
  },[height])

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth" 
  });
};
  useEffect(() => {
    if (
      !(
        controlArr.length >= 2 &&
        controlArr[controlArr.length - 1] === controlArr[controlArr.length - 2]
      )
    ) {
      setOn(false);
    }
  }, [controlArr.length]);

  useEffect(() => {
    setVideoLength(document.getElementsByClassName("video-item").length);
  }, [inpValue]);
  useEffect(() => {
    dispatch(getDataAndCategories());
  }, []);

  useEffect(() => {
    setScroolActive(true);
    document.getElementById("videolar").classList.add("linkActive");
  }, [scroolActive]);
  useEffect(() => {
    setInpActive(false);
    document.getElementById("rw_1_input")?.setAttribute("disabled", !inpActive);
  }, [inpActive]);

  useEffect(() => {
    if (videoKeys.categoryArr.length > 0) {
      document.getElementsByClassName('0Li')[0].classList.add("sidebarActive");
    }
  }, [videoKeys.categoryArr.length]);

  useEffect(() => {
    if (videoKeys?.categoryArr[0]?.name !== undefined) {
      changeFunc();
    }
  }, []);

  useEffect(() => {
    setDefaultData(
      videoKeys.allVideosArr?.filter((oItem) => {
        return oItem?.category_id == document.getElementsByClassName('select-li')[0].id;
      })
    );
    setDataName(
      videoKeys.categoryArr?.map((oItem) => {
        return oItem.name;
      })
    );
    setVideoLength(1);
  }, [videoKeys.allVideosArr?.length]);

  useEffect(()=>{
    var searchInput=document.querySelector('#search-Inp');
    if (searchActive) {
      searchInput.focus();
    }
  },[searchActive])

  const buyukHarfYapVeSiralaCumleler = (oItem) => {
    const kelimeler = oItem.split(" ");
    let yeniCumle = "";
    for (let i = 0; i < kelimeler.length; i++) {
      const kelime = kelimeler[i];
      const ilkHarf = kelime.charAt(0).toUpperCase();
      const geriKalan = kelime.slice(1);
      yeniCumle += ilkHarf + geriKalan + " ";
    }
    return yeniCumle.trim();
  };
  const dataFilter = (id) => {
    if (id != undefined) {
      setData(
        videoKeys.allVideosArr.filter((oItem) => {
          return oItem?.category_id == id;
        })
      );
    }
    // setActiveId(id);
  };
  const changeFunc = () => {
    for (let i = 0; i < dataName.length; i++) {
      document
        .getElementById("rw_2_listbox")
        ?.getElementsByClassName("rw-list-option")
      [i].addEventListener("click", function () {
        dataFilter(i + 1);
      });
    }
  };
  var countMap = {};
  videoKeys.allVideosArr?.forEach(function (item) {
    var id = item.category_id;
    countMap[id] = (countMap[id] || 0) + 1;
  });

  var result = Object.entries(countMap).map(function ([id, count]) {
    return { count: count };
  });

  const handleSearch = (e) => {
    setInpValue(e.target.value);
  };
  function changeWidth() {
    setWidth(window.innerWidth);
  }
  window.addEventListener('resize', changeWidth);
  useEffect(() => {
    if (width < 769) {
      document.getElementById('combobox').style.display = "block"
    } else {
      document.getElementById('combobox').style.display = "none"
    }
  }, [width])
  const handleClick = (index,oItem) => {
    if (disabledIndex !== oItem.id) {
      dataFilter(oItem.id);
      setControlArr((prevArray) => [...prevArray, index]);
    }
  }
  const handleSelectVideo = (e) => {
    dataFilter(e.target.value)
    setDisabledIndex(Number(e.target.value) )
  }
  return (
    <div id="allVideos">
      <ScrollToTop />
      <Navbar scroolActive={scroolActive} setScroolActive={setScroolActive} />
      <div style={{position:"relative"}} className="container">
        <button style={isShow ? {opacity:"1",padding:"1rem"}:{opacity:"0",padding:"1rem"}} className="back-top-div" onClick={scrollToTop}>
        <ArrowUpwardIcon sx={{fontSize:"28px"}}/>
        </button>   
        <div className="row">
          <div className="col-xxl-10 col-xl-12 col-lg-12 m-auto ">
            <div className="row">
              
              <div className="col-lg-6 col-none">
                <h2 className="services-title">
                  Videolarla Medikal Hizmetler{" "}
                </h2>
              </div>
              <div className="search-col-div col-lg-6 col-12">
                <div className="search-wrapper">
                  <div className="search-container d-flex justify-content-end">
                    <div className="search-input-box">
                      <input     
                        id="search-Inp"
                        onChange={handleSearch}
                        style={
                          searchActive ? { width: "100%" } : { width: "0%" }
                        }
                        placeholder="İçerik Ara"
                        type="text"
                      />
                    </div>
                    <div className="m-0 icon-box">
                      <div
                        onClick={()=>{setSearchActive(!searchActive)}}
                      >
                        {searchActive ? (
                          <i
                            style={{ borderRadius: "0 8px 8px 0" }}
                            className="fa-sharp fa-solid fa-xmark"
                          ></i>
                        ) : (
                          <i
                            style={{ borderRadius: "8px" }}
                            className="fa-sharp fa-solid fa-magnifying-glass"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid all-video-fluid">
              <div className="row allVideos-container">
                {/* sideBar */}
                <div id="sidebar" className=" col-xl-3 col-lg-4 col-sm-12">
                  <ul>
                    {videoKeys.categoryArr
                      ?.slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((oItem, oIndex) => {
                        const isDisabled = disabledIndex !== null && disabledIndex !== oIndex;
                        return (
                          <div key={oItem.id}>
                            <SidebarPiece 
                            setDisabledIndex={setDisabledIndex} oIndex={oIndex} oItem={oItem} handleClick={handleClick} setActiveId={setActiveId} buyukHarfYapVeSiralaCumleler={buyukHarfYapVeSiralaCumleler} result={result} isDisabled={isDisabled} disabledIndex={disabledIndex}
                            />
                          </div>
                            
                        );
                      })}
                  </ul>
                </div>
                {/* Video Bölümü */}
                <div className="videos-section col-xl-9 col-lg-8 col-sm-12">
                  <div style={videoLength === 0 ? { backgroundColor: "transparent" } : { backgroundColor: "white" }} id="combobox" className="mt-3 mb-3 ">
                    {videoKeys?.categoryArr[0]?.name !== undefined && (
                      <select style={videoLength === 0 ? { display: "none" } : { display: "block" }}  value={disabledIndex || ""} className="form-select rounded-0" aria-label="Default select example" onChange={(e) => handleSelectVideo(e)}>
                        {videoKeys.categoryArr
                      ?.slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((oItem, oIndex) => {
                            return (
                              <option className={`option-box`} id={oIndex} key={oIndex + 1} value={oItem.id}>
                                {oItem.name}
                              </option>
                            )
                          })}
                      </select>
                    )}
                  </div>
                  <div
                    style={
                      videoLength === 0
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    className="warning-write"
                  >
                    <div className="warning-wrapper">
                      <div
                        style={
                          inpValue.length <= 0
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        className="alert m-0 text-center alertMesage w-100"
                        role="alert"
                      >
                        <div className="info-box">
                          <i
                            style={{ marginRight: "12px", fontSize: "22px" }}
                            className="fa-solid fa-circle-info"
                          ></i>{" "}
                          <span className="section-write">
                            Aradığınız içerikte video bulunmamaktadır.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {data.length == 0 ? (
                      <>
                        {defaultData?.map((oItem, oIndex) => {
                          return (
                            <div key={oItem.youtube_video_id}>
                                <AllVideosPage
                              controlArr={controlArr}
                              on={on}
                              setOn={setOn}
                              oItem={oItem}
                              oIndex={oIndex}
                              inpValue={inpValue}
                            />
                            </div>
                          
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {data.map((oItem, oIndex) => {
                          return (
                            <div key={oItem.youtube_video_id}>
                            <AllVideosPage
                              controlArr={controlArr}
                              on={on}
                              setOn={setOn}
                              oItem={oItem}
                              oIndex={oIndex}
                              inpValue={inpValue}
                            />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllVideos;
