import React from 'react';
import "./css/Footer.css"
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import ImagePaths from './assets/images/ImagePaths';

const Footer = () => {
    return (
        <>
            <div className='main-footer'>
                <div className='footer-icon-wrapper'>
                    <a href="https://www.facebook.com/dr.osmangonulal/" target="_blank">
                        <img src={ImagePaths.facebookIcon} width="50px" alt="" />
                    </a>
                    <a href="https://www.instagram.com/dr.osmangonulal/" target="_blank">
                        <img src={ImagePaths.instagramIcon} width="50px" alt="" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCCthna8oogYAsCk-QLWXFig" target="_blank">
                        <img src={ImagePaths.youtubeIcon} width="50px" alt="" />
                    </a>
                    <a href="https://twitter.com/AEgitimleri" target="_blank">
                        <img src={ImagePaths.twitterIcon} width="50px" alt="" />
                    </a>
                </div>
                <div className='footer-p-wrapper'>
                    <p>BABYPRO Bilişim Teknolojileri Ticaret ve Sanayi Limited Şirketi</p>
                </div>
                <div className='footer-icon-container'>
                    <div className='phone-box'>
                        <CallIcon style={{ color: "#FFFFFF", paddingRight: "8px", fontSize: "29px" }} />
                        0532 399 01 66
                    </div>
                    <div className='mail-div'>
                    <a className='mail-box' href="mailto:md.gonulal@gmail.com" target='_blank'>
                        <MailIcon style={{ color: "#FFFFFF", paddingRight: "8px", fontSize: "29px" }} />
                        md.gonulal@gmail.com </a>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Footer;