import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./css/Videos.css";
import { EffectCoverflow, Pagination, Navigation, Thumbs } from "swiper";
import ImagePaths from "./assets/images/ImagePaths";
import SwiperCore, { Autoplay } from 'swiper';

const Videos = () => {
    SwiperCore.use([Autoplay]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="offset-xxl-1 col-xxl-10 offset-xxl-1 ">
                        <h2 className="home-title text-center" style={{ fontSize: "31.5px" }}>Öne Çıkan Videolar</h2>
                        <Swiper

                            initialSlide="1"
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={"auto"}
                            // autoplay={{
                            //     delay: 3500
                            // }}
                            loop={true}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            // pagination={true}
                            navigation={true}
                            modules={[EffectCoverflow, Pagination, Navigation, Thumbs]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <a target={"_blank"} href="https://www.youtube.com/playlist?list=PLvF5bPYH6JFtL_KkueBX0ciGw3l5Lgd1E">
                                    <img src={ImagePaths.linkImg_0} alt="" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a target={"_blank"} href="https://www.youtube.com/playlist?list=PLvF5bPYH6JFu7py7CG_yLsZGcAj5zXojI">
                                    <img src={ImagePaths.linkImg_1} alt="" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a target={"_blank"} href="https://www.youtube.com/playlist?list=PLvF5bPYH6JFu_34MNVQHe5wZCPTaip-sZ">
                                    <img src={ImagePaths.linkImg_2} alt="" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a target={"_blank"} href="https://www.youtube.com/playlist?list=PLvF5bPYH6JFsrlQWcDGd6S7u5cXxM6Tau">
                                    <img src={ImagePaths.linkImg_3} alt="" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a target={"_blank"} href="https://www.youtube.com/playlist?list=PLvF5bPYH6JFuTuezxnIwgsgF55bcuJXeE">
                                    <img src={ImagePaths.linkImg_4} alt="" />
                                </a>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Videos;
