import React, { useEffect, useState } from 'react';
import './css/ApplicationsPiece.css'

const ApplicationsPiece = ({ oItem, oIndex }) => {

    const animateClass = 
        oIndex == 0 || oIndex == 3 || oIndex == 6 ? 'wow db center h7 mb6 zoomInRight main-piece-container':
        oIndex == 1 || oIndex == 4 || oIndex == 7 ? 'wow fadeIn main-piece-container':
        oIndex == 2 || oIndex == 5 || oIndex == 8 ? 'wow db center h7 mb6 zoomInLeft main-piece-container':''
   

    return (
       
        <>
            <div data-wow-offset="30"
                data-wow-delay="0.2s" className={animateClass}>
                <div className='img-container-wrapper'>
                    <div className='image-container'>
                        <img src={oItem.img} alt="" width={"140px"} />
                    </div>
                </div>
                <div className='title-container'>
                    <h3 className='title-box'>
                        {oItem.title}
                    </h3>
                </div>
                <div className='write-container'>

                    <div className='write-box'>
                        {oItem.write}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApplicationsPiece;