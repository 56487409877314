import { configureStore } from "@reduxjs/toolkit";
import videosSlice from "../features/slices/VideoSlice";
import tokenSlice from "../features/slices/tokenSlice"

export const store = configureStore(
    {
        reducer: {
            catagories: videosSlice,
            token: tokenSlice
        }
    }
)